export const scTranslation = {
  slogan: "订阅 NFT 计划以获得空投 REVD 代币",
  changeLang: "Change Language",
  notification: {
    sorry: "抱歉，只差一点⋯",
    warning: {
      "input-email": "请输入您的电邮。",
      "valid-email": "请检查您的电邮是否输入正确。",
      "contact-us-email": "请联络我们以协助您登录。",
      walletNetworkErr: `请您连上网 {{network}} 区块链网络。 `,
      notSelectToken: `请选择您想打进的代币。 `,
      balanceNotEnough: `请确保您的钱包有足够的代币余额。 `,
    },
    success: {
      title: `出发`,
      title2: `恭喜`,
      walletConnected: `您已成功连上钱包，请选择适合您的计划。 `,
      orderSuccess: `已下单成功，您的订单号码是 {{orderId}}。 `,
    },
    error: {
      title: "抱歉，请重新尝试⋯",
    },
  },
  access: {
    title: "请输入您的电邮以登录",
  },
  btn: {
    access: "登录",
  },
  payment: {
    paymentQues: "请选择适合您的付款方式",
    planQues: "请选择适合您的 NFT 计划",
    tokenQues: "请选择您想打进的代币",
    jade: {
      descTxt: "2022 年 11 月 11 日空投 5% REVD 代币，其后每月空投 5%。",
    },
    platinum: {
      descTxt: "2023 年 5 月 11 日空投 10% REVD 代币，其后每月空投 10%。",
    },
    diamond: {
      descTxt: "2023 年 11 月 11 日空投 20% REVD 代币，其后每月空投 20%。",
    },
    contactUs: "联络我们",
    payNow: "立即付款",
    price: "价格",
    revdReceived: "REVD总额",
    bonus: "9月15前额外赠送",
    note: "备注",
    doNotClose: "您的付款正在进行中，请勿关闭或重启浏览器⋯",
    stripe: {
      succeeded: "付款成功！",
      processing: "付款进行中⋯",
      failed: "抱歉，您的付款不成功，请重新尝试。",
      500: "抱歉，请联络我们以协助您下单。",
    },
  },
  mint: {
    connect: "连结钱包",
  },
  status: {
    thankYou: `感谢您对我们的支持，我们会于 3 个工作天内联络您！ `,
    back: `返回主页`,
  },
};
