import React, { useState } from "react";
import { Container, Footer, Menu, VideoBg } from "../../components";
import { Access } from "./access";
import { Payment } from "./payment";
import { useAuthStore, useOrderStore } from "src/stores";
import { StripeForm } from "./stripe-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const Home = (props: any) => {
  const { access } = useAuthStore();
  const { createPendingOrder, updateOrder } = useOrderStore();
  const [stage, setStage] = useState<string>("start"); // start -> payment -> status
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [orderId, setOrderId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState<string>("crypto");
  const [plan, setPlan] = useState<string>("jade");
  const { t } = useTranslation();
  const search = useLocation().search;
  const referredBy = new URLSearchParams(search).get("referred_by");
  return (
    <Container>
      <Menu />
      <div className="home-page">
        <VideoBg />

        <div className="above-video center">
          <img
            src="/assets/logo_white.png"
            alt="logo"
            className="logo"
            onClick={() => setStage("start")}
          />
          <h1>{t("slogan")}</h1>
        </div>

        {/* Insert Email  */}
        {stage === "start" && (
          <Access
            setStage={setStage}
            loading={loading}
            setLoading={setLoading}
            access={access}
            email={email}
            setEmail={setEmail}
            referredBy={referredBy}
          />
        )}

        {/* Payment Section */}
        {stage === "payment" && (
          <Payment
            setStage={setStage}
            loading={loading}
            setLoading={setLoading}
            email={email}
            createPendingOrder={createPendingOrder}
            updateOrder={updateOrder}
            orderId={orderId}
            setOrderId={setOrderId}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            plan={plan}
            setPlan={setPlan}
            referredBy={referredBy}
          />
        )}

        {/* Stripe Form Section */}
        {stage === "stripe-form" && (
          <StripeForm
            setStage={setStage}
            loading={loading}
            setLoading={setLoading}
            email={email}
            createPendingOrder={createPendingOrder}
            updateOrder={updateOrder}
            orderId={orderId}
            setOrderId={setOrderId}
            paymentMethod={paymentMethod}
            plan={plan}
            referredBy={referredBy}
          />
        )}

        {/* Loading overlay */}
        {loading && <div className="loading-overlay" />}

        <Footer />
      </div>
    </Container>
  );
};
