import React from "react";
import { Rings } from "react-loader-spinner";

export const LoadingIcon = () => {
  return (
    <Rings
      height="20"
      width="20"
      color="#fff"
      radius="6"
      wrapperStyle={{}}
      wrapperClass="loading-icon"
      visible={true}
      ariaLabel="rings-loading"
    />
  );
};
