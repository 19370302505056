import React from "react";
import { useTranslation } from "react-i18next";
const { Dropdown, Menu: AntdMenu } = require("antd");

const SOCIAL_ICONS = [
  {
    name: "twitter",
    page: "https://twitter.com/REVDReverse",
  },
  {
    name: "medium",
    page: "https://medium.com/@ReverseDAO",
  },
  { name: "telegram", page: "https://t.me/+jpjnM8agpztiNjZl" },
];

export const Menu = (props: any) => {
  const { t, i18n } = useTranslation();

  const changeLang = (lang: string) => {
    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);
  };

  const langMenu = (
    <AntdMenu
      items={[
        {
          label: (
            <p className="lang-txt" onClick={() => changeLang("en")}>
              English
            </p>
          ),
          key: "0",
        },
        {
          label: (
            <p className="lang-txt" onClick={() => changeLang("tc")}>
              繁體中文
            </p>
          ),
          key: "1",
        },
        {
          label: (
            <p className="lang-txt" onClick={() => changeLang("sc")}>
              简体中文
            </p>
          ),
          key: "1",
        },
      ]}
    />
  );

  return (
    <div className="menu">
      <Dropdown overlay={langMenu} trigger={["click"]}>
        <p className="change-lang-txt">{t("changeLang")}</p>
      </Dropdown>
      <button
        onClick={() =>
          window.open("https://api.whatsapp.com/send?phone=85256357785")
        }
      >
        {t("payment.contactUs")}
      </button>
      {SOCIAL_ICONS.map((item, i) => {
        return (
          <img
            src={`/assets/${item.name}.png`}
            alt={`${item.name}-social`}
            className="social-icon"
            key={i}
            onClick={() => window.open(item.page)}
          />
        );
      })}
    </div>
  );
};
