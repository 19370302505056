import React from "react";
import { isMobile } from "react-device-detect";

export const VideoBg = (props: any) => {
  return (
    <div className="video-container">
      {/* bg */}
      {!isMobile && (
        <video autoPlay muted loop id="bg" className="desktop">
          <source src="/assets/bg.mp4" type="video/mp4" />
        </video>
      )}

      <div className="mobile mobile-bg" />

      <div className="overlay" />
    </div>
  );
};
