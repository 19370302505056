import React, { useCallback, useEffect, useState } from "react";
import { CheckoutForm, LoadingIcon } from "src/components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as Configs from "../../config";

export const StripeForm = (props: any) => {
  const { email, createPendingOrder, updateOrder, plan, orderId, setOrderId } =
    props;
  const [clientSecret, setClientSecret] = useState(null);
  const stripePromise = loadStripe(Configs.STRIPE_CONFIG.pk);
  const getOrderSecret = useCallback(async () => {
    const order = await createPendingOrder(email, "stripe", plan);
    setOrderId(order["id"]);
    setClientSecret(order["clientSecret"]);
    return;
  }, [createPendingOrder, email, plan, setOrderId, setClientSecret]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getOrderSecret();
  }, [getOrderSecret]);

  const appearance = {
    theme: "night",
  };
  const options: any = {
    clientSecret,
    appearance,
  };

  return (
    <div className="stripe-form-section above-video wrapper">
      {!clientSecret && <LoadingIcon />}

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            orderId={orderId}
            email={email}
            updateOrder={updateOrder}
          />
        </Elements>
      )}
    </div>
  );
};
