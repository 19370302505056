import React from "react";
import { Provider } from "mobx-react";
import Routers from "./router";
import { stores, StoresContext } from "./stores";
import * as Configs from "./config";
const { DAppProvider } = require("@usedapp/core");

function App() {
  return (
    <DAppProvider config={Configs.dAppsConfig}>
      <Provider {...stores}>
        <StoresContext.Provider value={stores}>
          <Routers />
        </StoresContext.Provider>
      </Provider>
    </DAppProvider>
  );
}

export default App;
