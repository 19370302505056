import React, { useEffect, useState } from "react";
import { checking, checkPlanPrice, getPlanPrice } from ".";
import { LoadingIcon } from "../loading-icon";
import * as Configs from "../../config";
import { formatUnits, parseUnits } from "@ethersproject/units";
import abiJson from "../../abi/erc20.json";
import { Contract } from "@ethersproject/contracts";
import { openNotificationWithIcon } from "../notification";
import { history } from "src/stores";
import { useTranslation } from "react-i18next";
const { useTokenBalance, useContractFunction } = require("@usedapp/core");
const { useEthers, useGasPrice } = require("@usedapp/core");

export const PayBtn = (props: any) => {
  const { t } = useTranslation();
  const {
    account,
    loading,
    depositToken,
    setLoading,
    setStage,
    plan,
    email,
    createPendingOrder,
    updateOrder,
  } = props;
  let selectedToken = Configs.getAcceptTokens().filter(
    (item) => item.name.toLowerCase() === depositToken
  )[0];
  const { contract, dp } = selectedToken;
  const { chainId } = useEthers();
  let tokenBalance = useTokenBalance(contract, account);
  if (tokenBalance) tokenBalance = formatUnits(tokenBalance, dp);
  const useDContract = new Contract(selectedToken["contract"], abiJson);
  const { send, state } = useContractFunction(useDContract, "transfer");
  const [orderId, setOrderId] = useState(null);
  const gasPrice = useGasPrice();

  const pay = async () => {
    const isChecked = checking(chainId, depositToken, t);
    if (!isChecked) return;

    setLoading(true);
    // 1: Create pending order
    const order = await createPendingOrder(email, "crypto", plan);
    setOrderId(order["orderId"]);

    // 2: Check if enough balance or not
    const passPriceChecked = checkPlanPrice(plan, tokenBalance, account, t);
    if (!passPriceChecked) {
      return setLoading(false);
    }

    // 3: Pay with plan
    const price = getPlanPrice(plan, account);
    const formattedPrice = parseUnits(String(price), dp).toString();
    send(Configs.RECEIVE_ADDRESS, formattedPrice, {
      gasPrice,
    });
  };

  useEffect(() => {
    setLoading(false);
    if (state) {
      switch (state.status) {
        case "Exception":
          openNotificationWithIcon(
            "error",
            t("notification.error.title"),
            state.errorMessage
          );
          break;
        case "PendingSignature":
        case "Mining":
          setLoading(true);
          break;
        case "Success":
          const txId = state.transaction?.hash;
          updateOrder(email, orderId, txId);
          openNotificationWithIcon(
            "success",
            t("notification.success.title2"),
            t("notification.success.orderSuccess", { orderId: orderId })
          );

          history.push("/status");
          setLoading(false);
          break;
      }
    }
    // state = Pending Signature, Exception,
  }, [state, setLoading, setStage, email, orderId, updateOrder, t]);

  return (
    <button className="primary full" onClick={() => pay()}>
      {loading ? <LoadingIcon /> : t("payment.payNow")}
    </button>
  );
};
