export const enTranslation = {
  slogan: "Subscribe our NFT Plan to receive REVD Token Airdrop",
  changeLang: "更改語言",
  notification: {
    sorry: "Sorry, one more step...",
    warning: {
      "input-email": "Please input your email.",
      "valid-email": "Please check your email or enter a valid email address.",
      "contact-us-email": "Pleaes contact us to assit your email address.",
      walletNetworkErr: `Please connect to the {{network}} network.`,
      notSelectToken: `Please select the token you'd like to deposit.`,
      balanceNotEnough: `Please top up your token before the deposit.`,
    },
    success: {
      title: `Let's go`,
      title2: `Congratualation`,
      walletConnected: `You've successfully connected to the wallet. Please select your desired plan.`,
      orderSuccess: `Successfully placed an order, your orderId is {{orderId}}`,
    },
    error: {
      title: "Sorry, please try again...",
    },
  },
  access: {
    title: "Please type in your email to access",
  },
  btn: {
    access: "Access",
  },
  payment: {
    paymentQues: "Please select your payment method",
    planQues: "Please select your desired NFT plan",
    tokenQues: "Please select your token to deposit",
    jade: {
      descTxt:
        "Release 5% REVD Token from 11 Nov 2022, then each month after will release 5% REVD Token.",
    },
    platinum: {
      descTxt:
        "Release 10% REVD Token from 11 May 2023, then each month after will release 10% REVD Token.",
    },
    diamond: {
      descTxt:
        "Release 20% REVD Token from 11 Nov 2023, then each month after will release 20% REVD Token.",
    },
    contactUs: "Contact Us",
    payNow: "Pay Now",
    price: "Price",
    revdReceived: "REVD Received",
    bonus: "Bonus before 15 Sept",
    note: "Note",
    doNotClose:
      "Please do not close or refresh the browser while the payment is in process...",
    stripe: {
      succeeded: "Payment succeeded!",
      processing: "Your payment is processing.",
      failed: "Your payment was not successful, please try again.",
      500: "Something went wrong, please contact us directly.",
    },
  },
  mint: {
    connect: "Connect",
  },
  status: {
    thankYou: `Thanks for your support, we will contact you shortly within 3 business days!`,
    back: `Back to Home`,
  },
};
