export const tcTranslation = {
  slogan: "訂閱 NFT 計劃以獲得空投 REVD 代幣",
  changeLang: "Change Language",
  notification: {
    sorry: "抱歉，只差一點⋯",
    warning: {
      "input-email": "請輸入您的電郵。",
      "valid-email": "請檢查您的電郵是否輸入正確。",
      "contact-us-email": "請聯絡我們以協助您登錄。",
      walletNetworkErr: `請您連上網 {{network}} 區塊鏈網絡。`,
      notSelectToken: `請選擇您想打進的代幣。`,
      balanceNotEnough: `請確保您的錢包有足夠的代幣餘額。`,
    },
    success: {
      title: `出發`,
      title2: `恭喜`,
      walletConnected: `您已成功連上錢包，請選擇適合您的計劃。`,
      orderSuccess: `已下單成功，您的訂單號碼是 {{orderId}}。`,
    },
    error: {
      title: "抱歉，請重新嘗試⋯",
    },
  },
  access: {
    title: "請輸入您的電郵以登錄",
  },
  btn: {
    access: "登錄",
  },
  payment: {
    paymentQues: "請選擇適合您的付款方式",
    planQues: "請選擇適合您的 NFT 計劃",
    tokenQues: "請選擇您想打進的代幣",
    jade: {
      descTxt: "2022 年 11 月 11 日空投 5% REVD 代幣，其後每月空投 5%。",
    },
    platinum: {
      descTxt: "2023 年 5 月 11 日空投 10% REVD 代幣，其後每月空投 10%。",
    },
    diamond: {
      descTxt: "2023 年 11 月 11 日空投 20% REVD 代幣，其後每月空投 20%。",
    },
    contactUs: "聯絡我們",
    payNow: "立即付款",
    price: "價格",
    revdReceived: "REVD總額",
    bonus: "9月15前額外贈送",
    note: "備注",
    doNotClose: "您的付款正在進行中，請勿關閉或重啟瀏覽器⋯",
    stripe: {
      succeeded: "付款成功！",
      processing: "付款進行中⋯",
      failed: "抱歉，您的付款不成功，請重新嘗試。",
      500: "抱歉，請聯絡我們以協助您下單。",
    },
  },
  mint: {
    connect: "連結錢包",
  },
  status: {
    thankYou: `感謝您對我們的支持，我們會於 3 個工作天內聯絡您！`,
    back: `返回主頁`,
  },
};
