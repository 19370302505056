import React from "react";
import { history } from "../../stores";

export const SwitchButton = (props: any) => {
  const { action, buttons, tab } = props;

  if (!buttons) return null;

  if (buttons.length === 1) {
    action(buttons[0].replace(" ", "-").toLowerCase());
  }

  const toPage = (item: string) => {
    return history.push(item);
  };

  return (
    <div className="switch-button">
      {buttons.map((item: any, i: any) => {
        const formattedItem: string = item.replace(" ", "-").toLowerCase();
        return (
          <button
            key={i}
            className={formattedItem === tab ? "active" : ""}
            onClick={
              action === "to-page"
                ? () => toPage(formattedItem)
                : () => action(formattedItem)
            }
          >
            {item}
          </button>
        );
      })}
    </div>
  );
};
