import React, { useEffect } from "react";
import { Container, Menu, VideoBg } from "src/components";
import { Footer } from "src/components/footer";
import { history, useOrderStore } from "../../stores";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Status = (props: any) => {
  const { t } = useTranslation();
  const { updateOrder } = useOrderStore();
  const search = useLocation().search;
  const orderId = new URLSearchParams(search).get("payment_intent");
  const email = new URLSearchParams(search).get("email");

  useEffect(() => {
    if (email && orderId) {
      updateOrder(email, orderId);
    }
  }, [email, orderId, updateOrder]);

  return (
    <Container>
      <Menu />
      <div className="home-page">
        <VideoBg />

        <div className="above-video center">
          <img
            src="/assets/logo_white.png"
            alt="logo"
            className="logo"
            onClick={() => history.push("/")}
          />
          <h1>{t("slogan")}</h1>
        </div>

        <div className="status-section above-video wrapper">
          <img
            src="assets/purchase_success.jpg"
            alt="success-icon"
            className="success-img"
          />
          <h2 className="mt-xl">{t("status.thankYou")}</h2>

          <div className="form">
            <button className="primary full" onClick={() => history.push("/")}>
              {t("status.back")}
            </button>
          </div>
        </div>

        <Footer />
      </div>
    </Container>
  );
};
