import React from "react";
import { LoadingIcon, openNotificationWithIcon } from "src/components";
import { useTranslation } from "react-i18next";

const MASCOWS = ["jade", "diamond", "platinum"];
export const Access = (props: any) => {
  const {
    loading,
    setLoading,
    setStage,
    access: callAccess,
    email,
    setEmail,
    referredBy,
  } = props;
  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleEmail = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const access = async () => {
    // Check email validation
    if (!email)
      return openNotificationWithIcon(
        "warning",
        t("notification.sorry"),
        t("notification.warning.input-email")
      );
    const isValid = validateEmail(email);
    if (!isValid) {
      return openNotificationWithIcon(
        "warning",
        t("notification.sorry"),
        t("notification.warning.valid-email")
      );
    }

    setLoading(true);

    const result = await callAccess(email, referredBy);
    if (!result)
      return openNotificationWithIcon(
        "error",
        t("notification.sorry"),
        t("notification.warning.contact-us-email")
      );
    setStage("payment");
    setLoading(false);
  };

  return (
    <div className="access-section above-video wrapper">
      <div className="intro-mascows">
        {MASCOWS.map((item, i) => {
          return (
            <img src={`/assets/${item}.png`} alt={`${item}-intro`} key={i} />
          );
        })}
      </div>

      <h2>{t("access.title")}</h2>

      {/* input and button */}
      <div className="form">
        <input type={"email"} onChange={handleEmail} />
        <button className="primary full" onClick={() => access()}>
          {loading ? <LoadingIcon /> : t("btn.access")}
        </button>
      </div>
    </div>
  );
};
