import { action } from "mobx";
import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";

export class OrderStore {
  @action
  async createPendingOrder(email: string, method: string, plan: string) {
    const url = `${Configs.apiUrl}/order/create-order`;
    try {
      const result: any = await axios.post(url, { email, method, plan });
      if (!result || !result["data"]) return generalError;
      return {
        orderId: result["data"]["orderId"],
        ...(result["data"]["clientSecret"] && {
          clientSecret: result["data"]["clientSecret"],
        }),
      };
    } catch (e) {
      return generalError;
    }
  }

  @action
  async updateOrder(email: string, orderId: string, txId?: string) {
    const url = `${Configs.apiUrl}/order/finish-order`;
    try {
      const result: any = await axios.post(url, {
        email,
        orderId,
        ...(txId && { txId }),
      });
      if (!result || !result["data"]) return generalError;
      return result["data"]["success"];
    } catch (e) {
      return generalError;
    }
  }
}

export const STORE_ORDER = "orderStore";
