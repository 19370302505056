import React, { useState } from "react";
import { LoadingIcon, SwitchButton, MintBtn } from "src/components";
import { getAcceptTokens } from "src/config";
import { useTranslation } from "react-i18next";

export const Payment = (props: any) => {
  const { t } = useTranslation();

  const PLAN_INFO = [
    {
      name: "aqua",
      price: "250",
      revd: "11,000",
      bonus: "+10%",
      desc: t("payment.jade.descTxt"),
    },
    {
      name: "jade",
      price: "2,500",
      revd: "120,000",
      bonus: "+20%",
      desc: t("payment.jade.descTxt"),
    },
    {
      name: "platinum",
      price: "30,000",
      revd: "1,560,000",
      bonus: "+30%",
      desc: t("payment.platinum.descTxt"),
    },
    {
      name: "diamond",
      price: "150,000",
      revd: "8,400,000",
      bonus: "+40%",
      desc: t("payment.diamond.descTxt"),
    },
  ];

  const {
    loading,
    setLoading,
    setStage,
    email,
    createPendingOrder,
    updateOrder,
    paymentMethod,
    setPaymentMethod,
    plan,
    setPlan,
  } = props;
  const [depositToken, setDepositToken] = useState<string | null>(null);

  const pay = async () => {
    if (paymentMethod === "others")
      return window.open("https://api.whatsapp.com/send?phone=85256357785");

    setLoading(true);
    setStage("stripe-form");
    setLoading(false);
  };

  const getPlanList = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "stripe":
        return ["Aqua", "Jade"];
      case "others":
      case "crypto":
      default:
        return ["Aqua", "Jade", "Platinum", "Diamond"];
    }
  };

  const getDepositToken = () => {
    const tokens: any = getAcceptTokens();
    let depositTokens: any = [];
    for (const token of tokens) {
      depositTokens = [...depositTokens, token.name];
    }
    return depositTokens;
  };

  const getPlanInfo = (plan: string) => {
    for (const i in PLAN_INFO) {
      if (PLAN_INFO[i].name === plan) {
        return PLAN_INFO[i];
      }
    }

    return PLAN_INFO[0];
  };

  const getPaymentButton = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "others":
        return t("payment.contactUs");
      case "stripe":
      case "crypto":
      default:
        return t("payment.payNow");
    }
  };

  return (
    <div className="payment-section above-video wrapper">
      <h2 className="mb-m">{t("payment.paymentQues")}</h2>
      <SwitchButton
        buttons={["Crypto", "Stripe", "Others"]}
        tab={paymentMethod}
        action={setPaymentMethod}
      />

      <h2 className="mt-xl mb-m">{t("payment.planQues")}</h2>
      <SwitchButton
        buttons={getPlanList(paymentMethod)}
        tab={plan}
        action={setPlan}
      />

      {paymentMethod === "crypto" && (
        <div>
          <h2 className="mt-xl mb-m">{t("payment.tokenQues")}</h2>
          <SwitchButton
            buttons={getDepositToken()}
            tab={depositToken}
            action={setDepositToken}
          />
        </div>
      )}

      <div className="plan-desc">
        <img src={`/assets/${plan}.png`} alt={`${plan}_img`} />
        <div className="plan-info">
          <p>
            <span>{t("payment.price")}</span> {getPlanInfo(plan).price} USD
          </p>
          <p>
            <span>{t("payment.revdReceived")}</span> {getPlanInfo(plan).revd}
          </p>
          <p>
            <span>{t("payment.bonus")}</span> {getPlanInfo(plan).bonus}
          </p>
          <p>
            <span>{t("payment.note")}</span> {getPlanInfo(plan).desc}
          </p>
        </div>
      </div>
      {/* input and button */}
      <div className="form">
        {paymentMethod === "crypto" && (
          <MintBtn
            loading={loading}
            setLoading={setLoading}
            email={email}
            setStage={setStage}
            plan={plan}
            depositToken={depositToken}
            createPendingOrder={createPendingOrder}
            updateOrder={updateOrder}
          />
        )}

        {paymentMethod !== "crypto" && (
          <button className="primary full" onClick={() => pay()}>
            {loading ? <LoadingIcon /> : getPaymentButton(paymentMethod)}
          </button>
        )}

        {loading && <p className="note mt-m">{t("payment.doNotClose")}</p>}
      </div>
    </div>
  );
};
