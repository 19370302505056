import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as Configs from "../../config";
import { LoadingIcon } from "../loading-icon";
import { useTranslation } from "react-i18next";

export const CheckoutForm = (props: any) => {
  const { t } = useTranslation();
  const { email, orderId } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      setMessage("");
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage(t("payment.stripe.succeeded"));
            break;
          case "processing":
            setMessage(t("payment.stripe.processing"));
            break;
          case "requires_payment_method":
            setMessage(t("payment.stripe.failed"));
            break;
          default:
            setMessage(t("payment.stripe.500"));
            break;
        }
      });
  }, [stripe, t]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error }: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${Configs.BASE_URL}/status?email=${email}&orderId=${orderId}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(t("payment.stripe.500"));
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        className="primary full mt-xl"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? <LoadingIcon /> : t("payment.payNow")}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message">
          <p className="mt-m note">{message}</p>
        </div>
      )}
    </form>
  );
};
