// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { STORE_AUTH, AuthStore } from "./auth";
import { STORE_ORDER, OrderStore } from "./order";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_ORDER]: new OrderStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useOrderStore() {
  const { orderStore } = useStores();
  return orderStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
